<template>
  <v-container>
    <h1>Import Student</h1>
    <v-alert class="mt-2" type="info" outlined text>
      The uploaded spreadsheet needs to have proper header.
      <a style="text-decoration: underline" @click="downloadTemplate"
        >Click here to download the template</a
      >
    </v-alert>
    <v-alert
      v-if="successes.length !== 0"
      class="mb-6"
      type="success"
      outlined
      text
    >
      <ul>
        <li v-for="(success, index) in successes" :key="index">
          {{ success }}
        </li>
      </ul>
    </v-alert>
    <v-alert
      v-if="errors.length !== 0"
      class="mb-6"
      type="warning"
      outlined
      text
    >
      Some data can't be processed:
      <ul>
        <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
      </ul>
    </v-alert>
    <v-sheet color="white" elevation="1" class="px-5 mt-4 rounded">
      <v-form ref="form" @submit.prevent="submit">
        <v-row>
          <v-col>
            <v-file-input
              v-model="users"
              accept=".csv"
              label="Select Spreadsheet"
            />
            <v-btn
              :disabled="this.users === null"
              :loading="loading"
              color="success"
              class="mt-2"
              type="submit"
            >
              Submit
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-sheet>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import { parse } from "papaparse";
import Student from "@/services/student";
import User from "@/services/user";

class StudentCreateError extends Error {
  constructor(message) {
    super(message);
    this.name = "StudentCreateError";
  }
}

class AddGroupError extends Error {
  constructor(message) {
    super(message);
    this.name = "AddGroupError";
  }
}

class UpdateStudentProfileError extends Error {
  constructor(message) {
    super(message);
    this.name = "UpdateStudentProfileError";
  }
}

export default {
  name: "Student.Import",
  data() {
    return {
      valid: false,
      users: null,
      loading: false,
      errors: [],
      successes: []
    };
  },
  async created() {
    this.setNavbarTitle("Manage Student");
  },
  methods: {
    ...mapActions("drawer", ["setNavbarTitle"]),
    async submit() {
      try {
        this.loading = true;
        this.errors = [];
        this.successes = [];
        const readFile = new Promise(resolve => {
          const reader = new FileReader();
          const vm = this;
          reader.onload = () => {
            resolve((vm.results = reader.result));
          };
          reader.readAsText(vm.users);
        });

        const file = await readFile;

        const parsed = await parse(file.trim(), {
          header: true,
          skipEmptyLines: true
        });

        for (const {
          email,
          group_code,
          name,
          password,
          phone_number,
          class: class_name,
          school_id
        } of parsed.data) {
          await new Promise(resolve => setTimeout(resolve, 500));
          let user;
          try {
            user = await User.create({
              email,
              name,
              password,
              password_confirmation: password,
              phone_number,
              role: "student"
            }).catch(error => {
              throw new StudentCreateError(error.response.data.message);
            });
            await Student.addGroup({
              user_id: user.data.data.id,
              group_code
            }).catch(error => {
              throw new AddGroupError(error.response.data.message);
            });
            await Student.updateProfile({
              user_id: user.data.data.id,
              class: class_name,
              school_id: school_id
            }).catch(error => {
              throw new UpdateStudentProfileError(error.response.data.message);
            });
            this.successes.push(`${name}: User created successfully`);
          } catch (error) {
            if (
              error instanceof AddGroupError ||
              error instanceof UpdateStudentProfileError
            ) {
              await User.delete(user.data.data.id);
            }
            this.errors.push(`${name}(${error.name}): ${error.message}`);
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
        this.users = null;
      }
    },
    downloadTemplate() {
      const element = document.createElement("a");
      const header =
        "email;name;phone_number;password;group_code;school_id;class";
      element.setAttribute("href", "data:text/csv;charset=utf-8," + header);
      element.setAttribute("download", "template.csv");

      element.style.display = "none";
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    }
  }
};
</script>
